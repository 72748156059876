<template>
	<div class="content">
		<div class="user_login">
			<el-popover
				placement="left"
				width="200"
				trigger="click">
				<div v-if="login_suc" :style="{background: appear_style==0?'rgba(0, 212, 102, 0.7)':'rgba(0, 212, 102, 0.5)'}" style="font-weight: 600;border-radius: 15px;color: #fff;padding: 15px;font-size: 15px;">
					<div style="margin-bottom: 10px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">昵称：{{user.nickname}}</div>

					<div style="margin-bottom: 10px;">等级：{{user.grade}}</div>
					<div style="margin-bottom: 10px;">
						 <el-popover
						    placement="bottom"
						    width="100"
							v-model="visible"
						    trigger="click">
							<div style="display: flex;margin-top: 10px;">
								<input maxlength="15"  v-model="user.nickname" style="margin-right: 10px;border-radius: 5px;padding: 5px;font-weight: 600;border: 0px;width: 150px;"/>
								<el-button @click="modifyNick" type="success" icon="el-icon-check" size="mini" circle></el-button>
							</div>
							
							<el-button slot="reference" style="margin-right: 5px;margin-left: 0px;display: inline;" size="mini" type="primary" round plain >编辑昵称</el-button>
						  </el-popover>
						<el-button @click="dingTalkCertifShow=true" style="margin-left: 0px;display: inline;" size="mini" type="success" round plain >等级认证</el-button>
					</div>
					<el-button @click="login_out" style="margin-left: 0px;display: inline;" size="mini" type="danger" round plain >退出登录</el-button>
				</div>
				<div v-else style="display: flex;justify-items: center;align-items: center;"><div :style="{color: appear_style==0?'#5d5d5d': 'aliceblue'}">微信扫码登录<br>关注公众号</div><img style="width: 100px;" :src="login_qr_code"/></div>
				<img class="user_login_avatar" slot="reference" @click="get_qrCode" src="../assets/DROCKART-LOGO-H216-1.png"/>
			</el-popover>
		</div>
		<i class="el-icon-sunny change_style" @click="change_style" style="box-shadow: 1px 1px #000" v-show="appear_style==0"></i>
		<i class="el-icon-moon change_style" style="color: aliceblue;" @click="change_style" v-show="appear_style==1"></i>
		<i class="el-icon-house go_home" :style="{color: appear_style==0?'#5d5d5d': 'aliceblue'}" @click="goHome"></i>
		<el-popover
		    placement="left"
		    width="400"
			@show="scroll_bottom"
		    trigger="click">
			<div style="margin-top: 10px;color: #fff;background-color: rgb(115,123,180,0.8);border-radius: 10px;padding: 10px;padding-top:0px;">
				<div style = "font-size: 18px;text-align: center;font-weight: 700;padding-top:10px;">问题反馈</div>
				<div id="feed_content" style = "padding: 15px;height: 500px;overflow: scroll;">
					<div style = "width: 100%;margin-top: 20px;display: grid;grid-template-columns: 50px auto;grid-template-rows: 50px auto;grid-template-areas: 'a b' 'c d';" v-for="(item, index) in mes_list" :key="index">
						<div style = "grid-area: a;display: flex;width: 40px;height: 100%;align-self: center;justify-self: center;user-select: none;">
							<img style = "width: 40px;height: 40px;border-radius: 20px;" :src="(item.role==0)?require('../assets/DROCKART-LOGO-H216-1.png'):require('../assets/boy1.jpg')"/>
						</div>
						<div style = "grid-area: b;font-weight: 700;padding: 10px;padding-top: 5px;border-radius: 5px;align-self: center;user-select: none;">{{item.role==0?"user":"客服"}}</div>
						<p style="white-space: pre-wrap;overflow: auto;grid-area: d;border-radius: 10px;padding: 15px;border-left: 0.1em solid #737bb4;margin-right: 10px;">{{item.content}}</p>
					</div>
					<div style="height: 120px;"></div>
				</div>
				<div style = "width: calc(100% - 20px);position: absolute;bottom: 0px;padding: 10px;">
					<div style = "padding-bottom: 10px;">
						<input @keydown.enter="feedbackSendMes" maxlength="3000"  v-model="feedback_value" style="margin-right: 10px;border-radius: 5px;padding: 8px 10px 8px 10px;font-weight: 600;border: 0px;width: 80%;display: inline;"/>
						<div style = "display: inline;">
							<el-button @click="feedbackSendMes" type="primary" plain icon="el-icon-position" style="padding: 3px;font-size: 16px;"></el-button>
						</div>
					</div>
				</div>
			</div>
			<i class="el-icon-edit-outline feedback" slot="reference" @click="feedbackGetMes" :style="{color: appear_style==0?'#5d5d5d': 'aliceblue'}"></i>
		</el-popover>
		<i class="el-icon-reading go_home" @click="open_url($event)" :style="{color: appear_style==0?'#5d5d5d': 'aliceblue'}" ></i>
		
		<el-dialog
		  title="钉钉等级认证 (如果点击头像无效请扫码)"
		  :visible.sync="dingTalkCertifShow"
			width="50%"
			:modal=false
		  :before-close="closeDingTalkCertifShow">
		  <div style="height: 500px;" v-if="dingTalkCertifShow">
		  	<iframe style="width: 100%;height: 100%;background: aliceblue;" :src="'https://login.dingtalk.com/oauth2/auth?redirect_uri=' + dingAuthIp + '/ding/auth&response_type=code&client_id='+clientId+'&scope=openid&state='+user_token+'&prompt=consent'"></iframe>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="closeDingTalkCertifShow">关闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
import {get_qrCode, is_login_suc, create_uuid} from "@/api/gpt_api";
import {getUserProfile,modifyNick,feedbackSendMes,feedbackGetMes} from "@/api/user_api";
import {getNotice} from "@/api/common_api";
import {ding_auth_ip, client_id, help_doc_url} from '@/api/environment'

export default {
data() {
	return {
		login_suc: false,
		login_qr_code:"",
		interval_is_login:"",	// 轮巡器
		interval_login_counter:0,  // 计数器，用于记录轮巡次数
		user_token:null,
		appear_style:0,
		user:{},
		visible: false,
		mes_list:[],
		feedback_value:"",
		first_send:true,
		dingTalkCertifShow: false,
		dingAuthIp: ding_auth_ip,
		clientId: client_id,
		helpDocUrl: help_doc_url
	}
},

mounted() {
	this.get_appear_style()
	this.verify_is_logining()
	this.getUserProfile()
	getNotice(this)
},


methods:{
	open_url(event){
		
		if(event!=null){
			event.stopPropagation();
		}
		window.open(this.helpDocUrl, '_blank');
	},
	closeDingTalkCertifShow(){
		this.dingTalkCertifShow = false
		this.getUserProfile()
	},
	goHome(){
		if (this.$route.path !== '/') {
		  this.$router.push('/');
		}
	},
	feedbackSendMes(){
		var that=this
		if(that.feedback_value==""){
			return
		}
		that.mes_list.push({role:0, content:that.feedback_value})
		feedbackSendMes(localStorage.getItem("user_token"),0,that.feedback_value).then(response => {
			if(response.status==200){
				if(that.first_send){
					that.mes_list.push({role:1, content:"已收到您的反馈！"})
					that.first_send=false
				}
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
		that.scroll_bottom()
		that.feedback_value=""
	},
	feedbackGetMes(){
		var that=this
		if(localStorage.getItem("user_token")!=null&&localStorage.getItem("user_token")!=""){
			feedbackGetMes(localStorage.getItem("user_token")).then(response =>{
				if(response.status==200){
					that.mes_list = response.res
					that.scroll_bottom()
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
				if(that.mes_list.length==0){
					that.mes_list.push({role:1, content:"您好，请问有什么问题吗？"})
				}
			})
		}
	},
	scroll_bottom(){
		setTimeout(()=>{
			// 获取指定元素
			var cont_rig = document.getElementById('feed_content'); // 替换为你的元素的ID
			cont_rig.scrollTop = cont_rig.scrollHeight;
		},100)
		
	},
	modifyNick(){
		var that=this
		console.log(that.user.nickname)
		if(that.user.nickname==null||that.user.nickname==""){
			this.$message({
				message: '用户名不能为空！',
				type: 'warning'
			});
			return
		}
		modifyNick(localStorage.getItem("user_token"),that.user.nickname).then(response => {
			if(response.status==200){
				that.visible = false
				this.$message({
					message: '修改成功！',
					type: 'success'
				});
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	getUserProfile(){
		var that=this
		var that=this
		if(localStorage.getItem("user_token")!=null){
			getUserProfile(localStorage.getItem("user_token")).then(response => {
				if(response.status==200){
					that.user=response.res
					console.log(response.res)
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		}
	},
	get_appear_style(){
		if(localStorage.getItem("appear_style")==null){
			this.appear_style=0
		}else{
			this.appear_style=localStorage.getItem("appear_style")
		}
	},
	change_style(){
		var that=this
		if(this.appear_style==0){
			this.appear_style=1
		}else{
			this.appear_style=0
		}
		localStorage.setItem("appear_style", that.appear_style)
		this.$emit('changeStyle', this.appear_style);
	},
	emitChange() {
		// 触发自定义事件 'change'，并传递一些数据（在这里用一个示例的字符串）
		this.$emit('change', 'Login Status Change!');
	},
	is_login_suc(){
		var that=this
		that.interval_login_counter++;
		is_login_suc(that.user_token).then(response => {
			if(response.status==200 && response.res){
				that.login_suc=true;
				localStorage.setItem("user_token", that.user_token);
				that.emitChange()
				that.getUserProfile()
				clearInterval(that.interval_is_login)
			}else{
				that.login_suc=false;
			}
		})
		if(that.interval_login_counter>=80){
			clearInterval(that.interval_is_login)
		}
		console.log(that.interval_login_counter)
	},
	
	verify_is_logining(){
		var that=this
		if(localStorage.getItem("user_token")!=null){
			that.user_token = localStorage.getItem("user_token")
			is_login_suc(that.user_token).then(response => {
				if(response.status==200 && response.res){
					that.login_suc=true;
				}else{
					that.login_suc=false;
				}
			})
		}else{
			that.login_suc=false;
		}
	},
	get_qrCode(){
		var that=this
		if(!that.login_suc){
			that.create_user_token()
			get_qrCode("login",that.user_token).then(response => {
				if(response.status==200){
					that.login_qr_code = response.res
					console.log("qrCode:"+response.res)
				}
			})
		}
		that.interval_login_counter=0
		clearInterval(that.interval_is_login)
		that.interval_is_login=setInterval(that.is_login_suc,1000)
	},
	create_user_token(){
		this.user_token = create_uuid()
		console.log("去掉破折号后的UUID:", this.user_token);
	},
	login_out(){
		localStorage.removeItem("user_token");
		this.login_suc=false
		this.emitChange()
		this.get_qrCode()
		// this.cur_mes=0;
		// this.mes_list=this.exm_mes_list
	},
},
}
</script>

<style>
.content{
	position: fixed;
	right: 15px;
	top: 10px;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	
	.user_login{
		
		.user_login_avatar{
			width: 60px;
			border-radius: 50%;
			user-select: none;
		}
	}
	.change_style{
		margin-top: 10px;
		font-weight: 900;
		/* box-shadow: 0.2px 0px #000; */
		border-radius: 50%;
		padding: 5px;
		font-size: 25px;
	}
	.go_home{
		margin-top: 10px;
		font-weight: 900;
		border-radius: 50%;
		padding: 5px;
		font-size: 25px;
	}
	.feedback{
		margin-top: 10px;
		font-weight: 900;
		border-radius: 50%;
		padding: 5px;
		font-size: 25px;
	}
}


</style>